import React, { useState } from "react";
import { MdBusiness, MdLogin, MdPersonAdd, MdMenu, MdArrowUpward } from "react-icons/md"; // Import MdArrowUpward
import SliderPage from "./pages/SliderPage";
/* import CategoriesPage from "./pages/CategoriesPage";
import CoursesPage from "./pages/CoursesPage";
import TestimonyPage from "./pages/TestimonyPage";
import FooterPage from "./pages/FooterPage"; */
import Login from "../views/login"; // Import the Login component
import SignUp from "../views/signup"; // Import the SignUp component
import StudentLogin from "../views/studentLogin"; // Import the SignUp component

function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false); // State for sign-up modal
  const [isStudentLoginModalOpen, setIsStudentLoginModalOpen] = useState(false); // State for sign-up modal

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };
  const openStudentLoginModal = () => {
    setIsStudentLoginModalOpen(true);
  };

  const closeStudentLoginModal = () => {
    setIsStudentLoginModalOpen(false);
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-tr from-[#FFFFFF] via-[#FFFFFF] to-[#FFFFFF]">
      {/* Navigation Bar */}
      <header className="bg-gradient-to-r from-black via-black to-black shadow-md">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center p-4 space-y-4 sm:space-y-0">
          {/* Logo Section */}
          <div className="flex items-center justify-between w-full sm:w-auto sm:flex-row">
            <button
              onClick={toggleMenu}
              className="sm:hidden text-white text-3xl"
            >
              <MdMenu />
            </button>

            <div className="flex items-center text-center sm:text-left">
              <MdBusiness className="text-blue-500 text-4xl sm:text-5xl mr-2 text-white" />
              <span className="text-2xl sm:text-4xl font-bold text-white">
                Mercy Medical University
              </span>
            </div>
          </div>

          {/* Mobile View: Navigation Buttons */}
          <div
            className={`sm:hidden flex flex-col space-y-4 ${isMenuOpen ? "block" : "hidden"}`}
          >
            <button
              onClick={openLoginModal}
              className="px-4 py-2 flex items-center border text-white border-white rounded-3xl hover:bg-[#FDD835] hover:text-white"
            >
              <MdLogin className="mr-2" /> Sign In
            </button>
            <button
              onClick={openStudentLoginModal}
              className="px-4 py-2 flex items-center bg-[#FDD835] text-black rounded-3xl hover:bg-[#E6C300] sm:flex-shrink-0"
            >
              <MdPersonAdd className="mr-2" /> Exam Portal
            </button> 
            
          </div>

          {/* Desktop View: Navigation Buttons */}
          <div className="hidden sm:flex sm:flex-row sm:space-x-4">
            <button
              onClick={openLoginModal}
              className="px-4 py-2 flex items-center border text-white border-white rounded-3xl hover:bg-[#FDD835] hover:text-white sm:flex-shrink-0"
            >
              <MdLogin className="mr-2" /> Sign In
            </button>
            <button
              onClick={openStudentLoginModal}
              className="px-4 py-2 flex items-center bg-[#FDD835] text-black rounded-3xl hover:bg-[#E6C300] sm:flex-shrink-0"
            >
              <MdPersonAdd className="mr-2" /> Exam Portal
            </button> 
          </div>
        </div>
      </header>

      {/* Main Content with Slider and Additional Image */}
      <SliderPage />

      {/* Categories Section */}
      {/* <CategoriesPage /> */}

      {/* Courses Section */}
      {/* <CoursesPage /> */}

      {/* Testimony */}
      {/* <TestimonyPage /> */}

      {/* Footer */}
      {/* <FooterPage /> */}

      {/* Modals */}
      {isLoginModalOpen && <Login closeModal={closeLoginModal} />}
      {isSignUpModalOpen && <SignUp closeModal={closeSignUpModal} />}
      {isStudentLoginModalOpen && <StudentLogin closeModal={closeStudentLoginModal} />}

      {/* Back to Top Button */}
      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 bg-[#FDD835] text-black p-3 rounded-full shadow-lg hover:bg-[#E6C300] transition-all"
      >
        <MdArrowUpward className="text-2xl" />
      </button>
    </div>
  );
}

export default LandingPage;
